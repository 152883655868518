import React, { useState,useEffect } from "react";
import Helpers from "./../../Config/Helpers";
import axios from "axios";
import { useGoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import BtnSpinner from "./../../Components/BtnSpinner";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
export default function Register() {
  useEffect(() => {
    document.title = "Register - Crownsync AI";
    // Optionally, set meta description or any other head elements here
    return () => {
      // This is where you can reset or change the title when the component unmounts if necessary
      document.title = "Crownsync Ai";
    };
  }, []);
  const [inputs, setInputs] = useState({});
  const [googleLoading, setGoogleLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const baseUrl = Helpers.apiUrl;
  const navigate = useNavigate();
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${baseUrl}register`, inputs, {
        headers: {
          Accept: "application/json", // Corrected header value capitalization
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        console.log(response.data);
        // localStorage.setItem("token", response.data.token); // Assuming the token is in response.data.token
        // localStorage.setItem("user", response.data.user);
        Helpers.setItem("user", response.data.user, true);
        Helpers.setItem("token", response.data.token);

        const ttoken = localStorage.setItem("token", response.data.token);
        console.log(ttoken, response.data.user);
        console.log("userrrr", response.data.user);
        // window.location.href = '/user/dashboard';
        navigate("/user/dashboard");
        Helpers.toast("success", "Login successfully"); // Assuming Helpers.toast is a valid method
      } else {
        // This else block might not be necessary if using catch for error handling
        Helpers.toast("warning", "An unexpected error occurred");
      }
    } catch (error) {
      // It's a good practice to handle errors in catch block
      console.error("Login error:", error);
      if (error.response) {
        // Server responded with a status code that falls out of the range of 2xx
        Helpers.toast(
          "error",
          error.response.data.message || "Failed to login"
        ); // Display error message from server if available
      } else {
        // Something happened in setting up the request that triggered an Error
        Helpers.toast(
          "error",
          "Login failed due to network error or server unavailability"
        );
      }
    }
  };
  useEffect(() => {
    // Set the initial background image when the component mounts
    document.body.style.backgroundImage = "url('media/auth/bg10.jpg')";

    // Listen for changes to the theme and update the background image
    const themeChangeHandler = () => {
      if (document.body.dataset.bsTheme === "dark") {
        document.body.style.backgroundImage =
          "url('/path/to/your/assets/media/auth/bg10-dark.jpg')";
      } else {
        document.body.style.backgroundImage =
          "url('/path/to/your/assets/media/auth/bg10.jpg')";
      }
    };

    // Example: Assuming there's an event you can listen to for theme changes
    document.body.addEventListener("themeChange", themeChangeHandler);

    // Cleanup function to remove the event listener
    return () => {
      document.body.removeEventListener("themeChange", themeChangeHandler);
    };
  }, []);

  return (
    // <div className="nk-app-root " data-sidebar-collapse="lg">
    //   <div className="nk-main">
    //     <div className="nk-wrap has-shape flex-column">
    //       <div className="nk-shape bg-shape-blur-a start-0 top-0"></div>
    //       <div className="nk-shape bg-shape-blur-b end-0 bottom-0"></div>
    //       <div className="text-center pt-5">
    //         <Link to="#!" className="logo-link"></Link>
    //       </div>
    //       <div className="container p-2 p-sm-4">
    //         <div className="row justify-content-center">
    //           <div className="col-md-7 col-lg-5 col-xl-5 col-xxl-4">
    //             <div className="nk-block">
    //               <div className="nk-block-head text-center mb-4 pb-2">
    //                 <div className="nk-block-head-content">
    //                   <h1 className="nk-block-title mb-1">
    //                     Create New Account
    //                   </h1>
    //                   <p className="small">
    //                     Create your new account to continue.
    //                   </p>
    //                 </div>
    //               </div>
    //               <form onSubmit={handleLogin}>
    //                 <div className="row gy-3">
    //                   <div className="col-12">
    //                     <div className="form-group">
    //                       <label className="form-label" htmlFor="name">
    //                         Name
    //                       </label>
    //                       <div className="form-control-wrap">
    //                         <input
    //                           className="form-control"
    //                           type="text"
    //                           name="name"
    //                           placeholder="Enter Name"
    //                           value={inputs.name}
    //                           onChange={handleChange}
    //                         />
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div className="col-12">
    //                     <div className="form-group">
    //                       <label className="form-label" htmlFor="email">
    //                         Email Address
    //                       </label>
    //                       <div className="form-control-wrap">
    //                         <input
    //                           className="form-control"
    //                           type="email"
    //                           name="email"
    //                           placeholder="Enter email address"
    //                           value={inputs.email}
    //                           onChange={handleChange}
    //                         />
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div className="col-12">
    //                     <div className="form-group">
    //                       <label className="form-label" htmlFor="password">
    //                         Password
    //                       </label>
    //                       <div className="form-control-wrap">
    //                         <Link
    //                           href="#!"
    //                           className="password-toggle form-control-icon end"
    //                           title="Toggle show/hide password"
    //                         >
    //                           <em className="icon ni ni-eye inactive"></em>
    //                           <em className="icon ni ni-eye-off active"></em>
    //                         </Link>
    //                         <input
    //                           className="form-control"
    //                           type="password"
    //                           id="password"
    //                           name="password"
    //                           placeholder="Enter password"
    //                           value={inputs.password}
    //                           onChange={handleChange}
    //                           required
    //                         />
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div className="col-12">
    //                     <Link className="link small" to="#">
    //                       Forgot password?
    //                     </Link>
    //                   </div>
    //                   <div className="col-12">
    //                     <div className="d-grid">
    //                       <button className="btn btn-primary" type="submit">
    //                         Login
    //                       </button>
    //                       {/* <button disabled={isLoading} onClick={loginWithGoogle} className="btn-auth btn  btn-danger mt-3">{googleLoading ? <BtnSpinner /> : <img src="/app/google.png" className="auth-icon" alt="" />} {googleLoading ? 'Please wait...' : 'Continue with Google'}</button> */}
    //                     </div>
    //                   </div>
    //                 </div>
    //               </form>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div class="d-flex flex-column flex-lg-row flex-column-fluid">
        <div class="d-flex flex-lg-row-fluid">
          <div class="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
            <img
              class="theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20"
              src="media/auth/signup.png"
              alt=""
            />
            {/* <img
              class="theme-dark-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20"
              src="media/auth/agency-dark.png"
              alt=""
            /> */}

<h1 class="text-gray-800 fs-2qx fw-bold text-center mb-7">
            Elevating Email Efficiency
            </h1>
            <div class="text-gray-600 fs-base text-center fw-semibold">
            Empower your inbox with,{" "}
              <a href="#" class="opacity-75-hover text-primary me-1">
              Crownsync's lightning-fast Gmail
              </a>
              management solution for seamless <br /> sending and responsive
              <a href="#" class="opacity-75-hover text-primary me-1">
              {" "}message handling
              </a>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12">
          <div class="bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10">
            <div class="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px">
              <div class="d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-20">
                <form
                  class="form w-100"
                  novalidate="novalidate"
                  id="kt_sign_up_form"
                  data-kt-redirect-url="/metronic8/demo52/authentication/layouts/overlay/sign-in.html"
                  action="#"
                  onSubmit={handleLogin}
                >
                  <div class="text-center mb-11">
                    {/* <h1 class="text-gray-900 fw-bolder mb-3">Sign Up</h1> */}

                    <div class="text-gray-500 fw-semibold fs-6">
                      
                    </div>
                  </div>
                  <div class="row g-3 mb-9">
                    <div class="col-md-6">
                      
                    </div>
                    <div class="col-md-6">
                     
                    </div>
                  </div>
                  <Link to='https://crownsync.ai/'>
                  <img
              class="theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20"
              src="media/auth/CROWNSYNCAI.png"
              alt=""
            />
                  </Link>
                  <div class="separator separator-content my-14">
                    <span class="w-125px text-gray-500 fw-semibold fs-7">
                      Register 
                    </span>
                  </div>

                  <div class="fv-row mb-8">
                    <input
                      type="text"
                      placeholder="Name"
                      name="name"
                      autocomplete="off"
                      class="form-control bg-transparent"
                      value={inputs.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="fv-row mb-8">
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      autocomplete="off"
                      class="form-control bg-transparent"
                      value={inputs.email}
                onChange={handleChange}
                    />
                  </div>

                  <div class="fv-row mb-8" data-kt-password-meter="true">
                    <div class="mb-1">
                      <div class="position-relative mb-3">
                        <input
                          class="form-control bg-transparent"
                          type="password"
                          placeholder="Password"
                          name="password"
                          autocomplete="off"
                          value={inputs.password}
                   onChange={handleChange}
                        />

                        <span
                          class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                          data-kt-password-meter-control="visibility"
                        >
                          {/* <i class="ki-outline ki-eye-slash fs-2"></i>{" "}
                          <i class="ki-outline ki-eye fs-2 d-none"></i>{" "} */}
                        </span>
                      </div>
                      {/* <div
                        class="d-flex align-items-center mb-3"
                        data-kt-password-meter-control="highlight"
                      >
                        <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                        <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                        <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                        <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                      </div> */}
                    </div>

                    {/* <div class="text-muted">
                      Use 8 or more characters with a mix of letters, numbers &
                      symbols.
                    </div> */}
                  </div>
                  <div class="fv-row mb-8">
                    
                  </div>
                  <div class="d-grid mb-10">
                    <button
                      type="submit"
                      id="kt_sign_up_submit"
                      class="btn "
                      style={{background:"#E2555E",color:"white"}}
                    >
                      <span class="indicator-label">Sign up</span>

                      <span class="indicator-progress">
                        Please wait...{" "}
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  </div>

                  <div class="text-gray-500 text-center fw-semibold fs-6">
                    Already have an Account? {" "}
                    <Link to="/" style={{color:"#E2555E"}}>
                      Sign in
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
